<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
         <div class="field">
            <label>Nama</label>
            <InputText v-model="item.userweb_nama" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_nama }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_nama" >{{ errorEdit.userweb_nama[0] }}</small >
         </div>
         <div class="field">
            <label>Username</label>
            <InputText v-model="item.userweb_username" required="true" autofocus :class="{ 'p-invalid': errorEdit.userweb_username }"/>
         </div>  <small class="p-invalid" style="color: red" v-if="errorEdit.userweb_username" >{{ errorEdit.userweb_username[0] }}</small >
        <div class="field" v-if="item.level_id == 2">
            <label>Distributor</label>
            <Dropdown ref="ddistributor" v-model="item.dist_code" :options="dataDropdownDistributor" optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor" :class="{ 'p-invalid': errorEdit.dist_code }" 
                :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'edit')"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.dist_code" >{{ errorEdit.dist_code[0] }}</small>
        </div>
        <div class="field" v-if="item.level_id == 3">
            <label>Region</label>
            <Listbox ref="dregion" v-model="item.region" dataKey="region_id" :options="dataDropdownRegion" :class="{ 'p-invalid': errorEdit.region_id }" :multiple="true" :filter="true" optionLabel="region_name"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownRegion($event, 'edit')">
                <template #option="slotProps">
                    <div>{{slotProps.option.region_name}}</div>
                </template>
            </Listbox>
            <small class="p-invalid" style="color: red" v-if="errorEdit.region_id">{{
                errorEdit.region_id[0]
            }}</small>
            <div class="mt-5" v-if="convertMultiSelectRegion(item.region).length != 0">
                <DataTable :value="item.region" :scrollable="true" scrollHeight="flex">
                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                            {{ slotProps.data.region_name }}
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="field">
            <label>Status</label>
            <Dropdown v-model="item.is_active" :options="dataDropdownStatus" optionLabel="name" optionValue="code" placeholder="Pilih Status" :class="{ 'p-invalid': errorEdit.is_active }"/>
                <small class="p-invalid" style="color: red" v-if="errorEdit.is_active" >{{ errorEdit.is_active[0] }}</small>
        </div>
         <div class="field">
            <label>Password</label> <small>*Silahkan isi jika ingin mengganti password</small >
            <Password v-model="item.password_new" toggleMask :feedback="false" :class="{ 'p-invalid': errorEdit.password }"/>
            <small class="p-invalid" style="color: red" v-if="errorEdit.password" >{{ errorEdit.password[0] }}</small >
        </div>
        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownDistributor: false,
            loadingDropdownRegion: false,

            // dataDropdown
            dataDropdownDistributor: null,
            dataDropdownRegion: null,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'AKTIF', code: 'y'},
                {name: 'TIDAK AKTIF', code: 'n'},
            ],

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});

        this.searchDropdownDistributor('');
        this.searchDropdownRegion('');
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        this.searchDropdownDistributor('', 'edit', this.item.dist_label);
                    }
                    this.$store.commit('setErrorEdit', {});
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        convertMultiSelectRegion(data) {
            if(data){
                return data.map((item) => {
                    return item.region_id;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "edit") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dregion.filterValue = valueEdit;
                }

                if (purpose == "edit") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "edit") {
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    } else if (purpose == null) {
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/update',
                data: {
                    "userweb_id": this.item.userweb_id,
                    "userweb_username": this.item.userweb_username,
                    "userweb_nama": this.item.userweb_nama,
                    "password": this.item.password,
                    "status_" : this.item.is_active == 'y' ? 1 : 0,
                    "dist_code": this.item.level_id == 2 ? this.item.dist_code : null,
                    "region_id": this.item.level_id == 3 ? this.convertMultiSelectRegion(this.item.region) : null,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Diupdate', life: 3000,});
            });
		},
    }
}
</script>
<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Nama</label>
                <InputText id="nama" v-model="forms.nama" :class="{ 'p-invalid': errorAdd.userweb_nama }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.userweb_nama" >{{ errorAdd.userweb_nama[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Username</label>
                <InputText id="username" v-model="forms.username" :class="{ 'p-invalid': errorAdd.userweb_username }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.userweb_username" >{{ errorAdd.userweb_username[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Password</label>
                <Password id="password" v-model="forms.password" toggleMask :feedback="false" :class="{ 'p-invalid': errorAdd.password }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.password" >{{ errorAdd.password[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Level</label>
                <Dropdown id="level" dataKey="level_id" v-model="forms.level_id" :loading="loadingDropdownLevel"
                    :options="dataDropdownLevel" :class="{ 'p-invalid': errorAdd.level_id }"
                    optionLabel="level_nama" optionValue="level_id" placeholder="Pilih Level"
                    :filter="true" :showClear="true" @filter="searchDropdownLevel($event, 'add')" @change="changeOptionLevel()"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.level_id">{{
                    errorAdd.level_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid" v-if="forms.level_id == 2">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Distributor</label>
                    <Dropdown id="dist_code" dataKey="dist_code" v-model="forms.dist_code" :loading="loadingDropdownDistributor"
                        :options="dataDropdownDistributor" :class="{ 'p-invalid': errorAdd.dist_code }" class="mt-2"
                        optionLabel="label" optionValue="dist_code" placeholder="Pilih Distributor"
                        :filter="true" :showClear="true" @filter="searchDropdownDistributor($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.dist_code">{{
                        errorAdd.dist_code[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid" v-if="forms.level_id == 3">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Region</label>
                <Listbox ref="dregion" v-model="forms.region_id" dataKey="region_id" :options="dataDropdownRegion" :class="{ 'p-invalid': errorAdd.region_id }" :multiple="true" :filter="true" optionLabel="region_name"  listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownRegion($event, 'add')">
                    <template #option="slotProps">
                        <div>{{slotProps.option.region_name}}</div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.region_id">{{
                    errorAdd.region_id[0]
                }}</small>
            </div>
            <div class="field col-12 md:col-6 lg:col-9" v-if="convertMultiSelectRegion(forms.region_id).length != 0">
                <div class="card" style="height: calc(60vh - 143px)">
                    <DataTable :value="forms.region_id" :scrollable="true" scrollHeight="flex">
                        <Column field="region_name" header="Region">
                            <template #body="slotProps">
                                {{ slotProps.data.region_name }}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownLevel: false,
            loadingDropdownDistributor: false,
            loadingDropdownRegion: false,

            // dataDropdown
            dataDropdownLevel: null,
            dataDropdownDistributor: null,
            dataDropdownRegion: null,

            // addNew
            forms: {
                nama: null,
                username: null,
                password: null,
                level_id: null,
                dist_code: null,
                region_id: [],
            },
        }
    },
    created(){
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});

        this.searchDropdownLevel('');
        this.searchDropdownDistributor('');
        this.searchDropdownRegion('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        convertMultiSelectRegion(data) {
            if(data){
                return data.map((item) => {
                    return item.region_id;
                });
            }else{
                return [];
            }
        },
        // DROPDOWN
        searchDropdownLevel(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dlevelweb.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownLevel = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-web-level',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownLevel = res.data.data;
                            this.loadingDropdownLevel = false;
                        } else if (purpose == null) {
                            this.dataDropdownLevel = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeOptionLevel(){
            if(this.forms.level_id == 2){
                this.forms.region_id = []
            }else{
                this.forms.dist_code = null
            }
        },
        searchDropdownDistributor(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.ddistributor.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownDistributor = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-distributor-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "add") {
                            this.dataDropdownDistributor = res.data.data;
                            this.loadingDropdownDistributor = false;
                        } else if (purpose == null) {
                            this.dataDropdownDistributor = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.dregion.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownRegion = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownRegion = res.data.data;
                        this.loadingDropdownRegion = false;
                    } else if (purpose == null) {
                        this.dataDropdownRegion = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/create',
                data: {
                    "userweb_nama": this.forms.nama,
                    "userweb_username": this.forms.username,
                    "password": this.forms.password,
                    "level_id": this.forms.level_id,
                    "dist_code": this.forms.level_id == 2 ? this.forms.dist_code : null,
                    "region_id": this.forms.level_id == 3 ? this.convertMultiSelectRegion(this.forms.region_id) : null,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.nama = null;
            this.forms.password = null;
            this.forms.username = null;
            this.forms.level_id = null;
            this.forms.dist_code = null;
            this.forms.region_id = [];
        },
    }
}
</script>